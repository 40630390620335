* {
  box-sizing: border-box;
}

input[type="text"],
select,
textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
  border-radius: 5px;
}

input[type="submit"] {
  font-weight: 500;
  display: inline-block;
  text-align: center;
  font-size: 1rem;
  background-color: #ffffff;
  /* transition: background-color 0.3s; */
  color: #ffffff;
  /* box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);  */
  border-radius: 0.4rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: 0.5rem;
  /* transition-duration: 0.3s; */
  width: 15rem;
  height: 2.5rem;
  color: black;
  text-transform: uppercase;
}

input[type="submit"]:hover {
  font-weight: 500;
  display: inline-block;
  text-align: center;
  font-size: 1rem;
  background-color: #6366f1;

  color: #ffffff;

  border-radius: 0.4rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: 1rem;
  margin-top: 0.5rem;

  width: 10rem;
  height: 2.5rem;
}

.container-contact {
  border-radius: 5px;

  padding: 10px;
}

.column-contact {
  float: left;
  width: 40%;
  margin-top: 6px;
  padding: 50px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.row-contact:after {
  content: "";
  display: table;
  clear: both;
}

@media screen and (max-width: 768px) {
  .column-contact,
  input[type="submit"] {
    width: 100%;
    margin-top: 0;
  }
}
@media screen and (max-width: 1440px) {
  .column-contact,
  input[type="submit"] {
    width: 100%;
    margin-top: 0;
  }
}
