@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  background-color: white;
}

.nav-elements {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; 
  width: 100%; 
}

.logo {
  margin-right: 10%;
}

.navbar {
  height: 100px;
  background-color: #ffffff;
  position: relative;
  margin-top: 10px;
}

.nav-elements ul {
  display: flex;
  justify-content: flex-end; 
  list-style-type: none;
  align-items: center;
  padding-right: 3rem; 
  
}

.nav-elements ul li:not(:last-child) {
  margin-right: 150px;
}

.nav-elements ul a {
  font-size: 18px;
  font-weight: 400;
  color: #333333;
  text-decoration: none;
}

.nav-elements ul a.active {
  /* color: #f6ff00;
  font-weight: 500;
  position: relative; */
}

.nav-elements ul a.active::after {
  /* content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #574c4c; */
}

.nav-button {
  font-weight: 500;
  display: inline-block;
  text-align: center;
  font-size: 1rem;
  background-color: #333333;
  transition: background-color 0.3s;
  color: #ffffff;
  /* box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); */
  border-radius: 0.4rem;
  padding-left: 1rem;
  padding-right: 1rem;
  /* margin-left: ; */
  margin-top: 0.5rem;
  /* margin-right: 20%; */
  transition-duration: 0.3s;
  width: 10rem;
  height: 2.5rem;
}

.nav-button-li{
  display: none;
}

.hamburger {
  border: 0;
  height: 40px;
  width: 40px;
  padding: 0.5rem;
  border-radius: 50%;
  background-color: #333333;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  display: none;
}
.hamburger:hover {
  background-color: #333333;
}

@media screen and (max-width: 1440px) {
  .hamburger {
    display: block;
    margin-right: 0.5rem;
  }
  .nav-container {
    margin: 0 40px;
  }
  .nav-elements ul {
    display: none;
    padding-top: 10px;
  }
  .nav-elements ul {
    position: absolute;
    top: 60px;
    left: 0;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 77px);
    background-color: white;
    /* border-top: 1px solid black; */
    display: none;
    
  }
  .nav-elements li {
    text-align: center;
    margin-top: 5%;
  }
  .nav-elements li a {
    color: black;
    width: 100%;
    padding: 1.5rem 0;
  }
  .nav-elements li:hover {
    background-color: #eee;
  }
  .navigation-menu.expanded ul {
    display: block;
  }
  .nav-button {
    display: none;
  }
  .logo {
    padding-left: 0%;
    margin-top: 1rem;
  }
  .nav-elements ul li:not(:last-child) {
    margin-right: 0px;
  }
  .nav-button-li{
    font-weight: 500;
    display: inline-block;
    text-align: center;
    font-size: 1rem;
    background-color: #333333;
    transition: background-color 0.3s;
    color: #ffffff;
    /* box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); */
    border-radius: 0.4rem;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-left: 1rem; /* Adjust the margin as needed */
    margin-top: 0.5rem;
    margin-right: 3rem;
    transition-duration: 0.3s;
    width: 10rem;
    height: 2.5rem;
  }
}


@media screen and (max-width: 768px) {
  .nav-container {
    margin: 0 40px;
  }
  .nav-elements ul {
    display: none;
  }
  .topnav a.icon {
    float: right;
    display: block;
  }
  .hamburger {
    display: block;
    margin-right: 0.5rem;
  }
  .nav-elements ul {
    display: none;
    padding-top: 10px;
  }
  .nav-elements ul {
    position:absolute;
    top: 60px;
    /* padding-left: 20px; */
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 77px);
    background-color: white;
    /* border-top: 0.5px solid black; */
    display: none;
  }
  .nav-elements li {
    text-align: centre;
    margin-top: 5%;
    
 
  }
  .nav-elements li a {
    color: black;
    width: 100%;
    padding: 1.5rem 0;
    
  }
  .nav-elements li:hover {
    background-color: #eee;
  }
  .navigation-menu.expanded ul {
    display: block;
   
  }
  .nav-button {
    display: none;
  }
  .nav-elements ul li:not(:last-child) {
    margin-right: 0px;
  }
  .nav-elements ul li{
    align-items: center;
  }
  .logo {
    margin-top: 1.2rem;
    margin-right: 50%;
  }
  .nav-button-li{
    font-weight: 500;
    display: inline-block;
    text-align: center;
    font-size: 1rem;
    background-color: #333333;
    transition: background-color 0.3s;
    color: #ffffff;
    /* box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); */
    border-radius: 0.4rem;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-left: 3rem; 
    margin-top: 0.5rem;
    margin-right: 3rem;
    transition-duration: 0.3s;
    width: 10rem;
    height: 2.5rem;
  }
}