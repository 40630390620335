.row-a2x {
    display: flex;
    flex-direction: row;
    padding: 0 4px;
    justify-content: center;
    text-align: center;
  }

  @media (max-width: 800px) {


  }

  
  .next-case{
    font-weight: 500;
    display: inline-block;
    text-align: center;
    font-size: 1rem;
    background-color: #ffffff;
    border-radius: 0.4rem;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-top: 0.5rem;
    width: 15rem;
    height: 2.5rem;
    color: black;
    text-transform: uppercase;
  }
  

  .column-a2x {
    flex: 25%;
    max-width: 25%;
    padding: 50px 40px;
    justify-content: center;
  }
  
  .column-a2x img {
    margin-top: 10px;
    vertical-align: middle;
    width: 100%;
  }
  
 
  
  @media screen and (max-width: 600px) {
    .column-a2x {
      flex: 100%;
      max-width: 100%;
    }
  }


  .sidebar-a2x {
    flex:50%;
    height: 100%; /* Full-height: remove this if you want "auto" height */
    width: 250px; /* Set the width of the sidebar */
   position: absolute;
   margin-left: 50px;
    
  }
  
  /* Sidebar links */
  .sidebar-a2x h1 {
    display: block;
    color: black;
    padding: 16px;
    text-decoration: none;
  }
  /* Sidebar links */
  .sidebar-a2x p {
    display: block;
    color: black;
    padding: 16px;
    text-decoration: none;
  }
  /* Sidebar links */
  .sidebar-a2x ul {
    display: block;
    color: black;
    padding: 16px;
    text-decoration: none;
  }
  
 
  
 
  @media screen and (max-width: 400px) {
    .sidebar-a2x a {
      text-align: center;
      float: none;
    }
  }

  .main-text-area-a2x {
    margin-left: 200px;
    padding: 0 350px;
    margin-bottom: 100px;
    
    
  }

  @media  screen and (max-width: 768px) {
    .main-text-area-a2x {
      flex:100%;
      padding: 0 30px;
      margin-left: 0px;
      margin: 10px
    }
    .sidebar-a2x {
      flex: 100%;
      height: auto;
      position: relative;
    }
    .sidebar-a2x a {float: left;}
    div.content {margin-left: 0;}
    .row-a2x {
      flex-direction: column;
      margin: 0 10px
    }
    .column-a2x {
      flex: 1000%;
      max-width: 100%;
    }
  }

  @media  screen and (max-width: 1440px) {
    .main-text-area-a2x {
      flex:100%;
      padding: 0px 30px;
      padding-bottom: 0px;
      margin-left: 0px;
      margin: 40px;
      
    }
    .row-a2x {
      flex-direction: column;
      margin: 0 0px;
    }
    .column-a2x {
      flex: 1000%;
      max-width: 100%;
    }
    .sidebar-a2x {
      flex: 100%;
      height: auto;
      position: relative;
      width: 70%;
    }
  }

  .container-a2x {
    margin-left: 160px; 
  }